import React, { useContext } from "react";
import AuthContext from '../context/AuthContext'

const Header = () => {


  return (
    <header class="text-white bg-slate-600 body-font">
      <div class="container mx-auto flex flex-wrap justify-center md:p-3 pt-2 flex-col md:flex-row items-center">
        <a class="flex title-font font-medium items-center mb-4 md:mb-0">
          <span class="ml-3 text-xl">MJManagement</span>
        </a>
  </div>
</header>
  )
}

export default Header