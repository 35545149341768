import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";

const Galeria = () => {
  const { id } = useParams();
  const [anuncio, setAnuncio] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const galleryRef = useRef(null);

  useEffect(() => {
    const buscarAnuncio = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/anuncios/${id}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if(response.ok){
          const data = await response.json();
          setAnuncio(data);
        } else {
          console.error('Falha ao buscar anúncio.');
        }
      } catch (error) {
        console.error('Erro ao buscar anúncio: ', error)
      }
    };

    const buscarImagens = async () => {
      try {
        const response = await fetch (`http://localhost:8000/api/anuncios/${id}/imagens/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if(response.ok){
          const data = await response.json();
          setImagens(data);
          if (data.length > 0) {
            setSelectedImage(data[0]);
          }
        } else {
          console.error('Falha ao buscar imagens.');
        }
      } catch(error) {
        console.error('Erro ao buscar imagens: ', error);
      }
    };

    buscarAnuncio();
    buscarImagens();
  }, [id]);

  const handleImageClick = (index) => {
    setSelectedImage(imagens[index]);
    setCurrentIndex(index);
  }

  const handleNext = () => {
    if (currentIndex < imagens.length - 1) {
      const newIndex = currentIndex + 1;
      setSelectedImage(imagens[newIndex]);
      setCurrentIndex(newIndex);
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setSelectedImage(imagens[newIndex]);
      setCurrentIndex(newIndex);
    }
  }

  const scrollGallery = (direction) => {
    if (galleryRef.current) {
      const scrollAmount = direction === 'left' ? -150 : 150;
      galleryRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  if (!anuncio) {
    return <div>Anúncio não existe.</div>
  }

  return (
    <div className="w-full flex flex-col md:flex-row md:justify-center">
      <div className="md:w-6/12 md:px-3 md:py-2 p-2 mx-2 my-2 rounded bg-slate-100">
        <div className="flex flex-col gap-1">
          <div className="relative main-img h-[500px]">
            {selectedImage && <img src={selectedImage.images} className="w-full h-full object-contain"/>}
            <div className="text-right mt-2 absolute top-1 right-1 text-white bg-slate-900 text-sm rounded bg-opacity-40 p-1">
            {currentIndex + 1}/{imagens.length}
          </div>
            <button 
              className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 font-bold text-slate-900 opacity-40 text-3xl disabled:opacity-20" 
              onClick={handlePrevious} 
              disabled={currentIndex === 0}
            >
              &lt;
            </button>
            <button 
              className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 font-bold text-slate-900 opacity-40 text-3xl disabled:opacity-20" 
              onClick={handleNext} 
              disabled={currentIndex === imagens.length - 1}
            >
              &gt;
            </button>
          </div>
          <div className="relative md:w-[650px] w[300px]">
            <button 
              className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-2 bg-gray-500 text-white rounded bg-opacity-70" 
              onClick={() => scrollGallery('left')}
            >
              &lt;
            </button>
            <div ref={galleryRef} className="gallery-tab flex overflow-hidden">
              {imagens.map((imagem, index) => (
                <div key={index} className={`h-20 w-20 flex-shrink-0 overflow-hidden mx-1 ${currentIndex === index ? 'selected' : ''}`}>
                  <img src={imagem.images} alt={`Imagens do imóvel`} className="h-full w-full object-cover rounded cursor-pointer" onClick={() => handleImageClick(index)} />
                </div>
              ))}
            </div>
            <button 
              className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-2 bg-gray-500 text-white rounded bg-opacity-70" 
              onClick={() => scrollGallery('right')}
            >
              &gt;
            </button>
            
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Galeria;
