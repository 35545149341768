import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';

import Header from './components/Header';
import DetalhesAnuncio from './pages/DetalhesAnuncio';
import Galeria from './pages/Galeria'

import { AuthProvider } from './context/AuthContext'

function App() {
  return (
    <div className="App bg-slate-200">
      <Router>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path='/anuncio/:id' element={<DetalhesAnuncio />}/>
            <Route path='/anuncio/:id/galeria' element={<Galeria />}/>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
