import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const DetalhesAnuncio = () => {
  const { id } = useParams();
  const [anuncio, setAnuncio] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const buscarAnuncio = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/anuncios/${id}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if(response.ok){
          const data = await response.json();
          setAnuncio(data);
        } else {
          console.error('Falha ao buscar anúncio.');
        }
      } catch (error) {
        console.error('Erro ao buscar anúncio: ', error)
      }
    };

    const buscarImagens = async () => {
      try {
        const response = await fetch (`http://localhost:8000/api/anuncios/${id}/imagens/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if(response.ok){
          const data = await response.json();
          setImagens(data);
          if (data.length > 0) {
            setSelectedImage(data[0]);
          }
        } else {
          console.error('Falha ao buscar imagens.');
        }
      } catch(error) {
        console.error('Erro ao buscar imagens: ', error);
      }
    };

    buscarAnuncio();
    buscarImagens();
  }, [id]);

  const handleImageClick = (index) => {
    setSelectedImage(imagens[index]);
  }

  if(!anuncio){
    return <div>Anúncio não existe.</div>
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
  }
  return (
    <div className="w-full flex flex-col md:flex-row md:justify-center">
      <div className="md:w-6/12 md:mt-4 md:px-3 md:py-2 p-2 mx-2 my-2 rounded bg-slate-100">
        <div className="grid gap-1">
          <div className="md:h-[500px]">
          {selectedImage && <img src={selectedImage.images} className=" w-full h-full object-contain rounded"/>}
          </div>
            <Slider {...settings}>
            {imagens.map((imagem, index) => (
              <div key={index} className="h-20 w-full overflow-hidden">
                  <img src={imagem.images} alt={`Imagens do imóvel`} className="h-full w-full object-cover rounded cursor-pointer" onClick={()=> handleImageClick(index)}/>
                </div>
                ))}
            </Slider>
          
        </div>
      </div>
      <div className="w-auto">
        <div className="flex flex-col md:items-start md:mt-36 bg-slate-50 p-8 rounded">
          <h1 className="title-font sm:text-4xl text-3xl mb-2 text-gray-900">{anuncio.titulo}</h1>
          <h4 className="mb-3 md:text-2xl text-xl leading-relaxed font-bold text-gray-900">€{anuncio.renda}</h4>
          <p className='text-gray-500 text-xs tracking-widest title-font mb-1'>DESCRIÇÃO</p>
          <p className="mb-3 leading-relaxed">{anuncio.descricao}</p>
        </div>
      </div>
    </div>
  ) 
}

export default DetalhesAnuncio;